.sliderMenus {
  > div > div {
    min-width: 100% !important; // needed if items number is less than "slidesToShow"
  }
}

.modal {
  // width: 80%;
  // padding: 4em 2em;
  > div[role='dialog'] {
    width: 80%;
    height: auto;
    max-width: 400px;
    max-height: 600px;
    border-radius: 6px;

    // .ion-page wrapper
    > div {
      // Allow height auto
      position: relative;
      contain: none;
    }
  }
  .modalContent {
    padding: 2em;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;

    .buttonContainer {
      .buttonQuantity {
        flex: 1;
      }
    }
  }
}
