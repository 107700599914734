.root {
  margin-bottom: 1em;
  .menuItemImage {
    width: 100%;
  }
}

.loader {
  margin-right: 12px;
  transform: scale(0.8);
}
