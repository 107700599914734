.LoginForm-container {
}

.LoginForm-container form {
  display: flex;
  flex-direction: column;
}
.LoginForm-container form .item-input {
  border-radius: 8px;
  margin-top: 1em;
  --border-color: transparent;
}
