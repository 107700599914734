.cardHeader {
    display: flex;

    .imgBg {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        min-width: 25%;
    }

    .cardTitle {
        font-size: 1.6em; // reduce
        font-weight: 500;
    }
}