.root {
  .menuItemImage {
    width: 100%;
  }
}
.rootIOS {
  margin-bottom: 0.8em;
  margin-top: 0.8em;
}

.rootMd {
  margin-bottom: 1em;
}
